import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import defaultValues from "./defaultValues";

export const SavedColorsContext = createContext(defaultValues);
export const useSavedColorsContext = () => useContext(SavedColorsContext);

const I18N_BASE_PATH = "src.contexts.savedColors";

/**
 * Saves colors to be used later.
 * Initially saves the primary, secondary, titles and illustrations colors.
 * */
export const SavedColorsProvider = ({ children }) => {
  const { t } = useTranslation();

  const [savedColors, setSavedColors] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);

  const { onboardingOptions } = useOnboardingOptionsContext();

  // Initializes some colors to be pre-saved
  useEffect(() => {
    if (!isInitialized) {
      if (onboardingOptions?.colors) {
        let { primary, secondary, headings, illustrations, buttonBackground, buttonText } = onboardingOptions?.colors;
        let newSavedColors = [];

        if (primary) {
          newSavedColors.push({ title: t(`${I18N_BASE_PATH}.colors.primary`, "Primária"), hex: primary });
        }
        if (secondary && !newSavedColors.filter((color) => color.hex === secondary).length) {
          newSavedColors.push({ title: t(`${I18N_BASE_PATH}.colors.secondary`, "Secundária"), hex: secondary });
        }
        if (headings && !newSavedColors.filter((color) => color.hex === headings).length) {
          newSavedColors.push({ title: t(`${I18N_BASE_PATH}.colors.headings`, "Títulos"), hex: headings });
        }
        if (illustrations && !newSavedColors.filter((color) => color.hex === illustrations).length) {
          newSavedColors.push({
            title: t(`${I18N_BASE_PATH}.colors.illustrations`, "Ilustrações"),
            hex: illustrations,
          });
        }
        if (buttonBackground && !newSavedColors.filter((color) => color.hex === buttonBackground).length) {
          newSavedColors.push({
            title: t(`${I18N_BASE_PATH}.colors.buttonBackground`, "Botão"),
            hex: buttonBackground,
          });
        }
        if (buttonText && !newSavedColors.filter((color) => color.hex === buttonText).length) {
          newSavedColors.push({ title: t(`${I18N_BASE_PATH}.colors.buttonText`, "Texto do botão"), hex: buttonText });
        }

        setSavedColors(newSavedColors);
        setIsInitialized(true);
      }
    }
  }, [onboardingOptions]);

  /** Saves a new hex color */
  const saveColor = (hex) => {
    setSavedColors((old) => [...old, { title: hex, hex }]);
  };

  /** Unsaves a hex color */
  const unsaveColor = (hex) => {
    let colorIndex = savedColors.filter((color) => !color.fixed).findIndex((color) => color.hex === hex);
    if (!isNaN(colorIndex))
      setSavedColors((old) => [...old.slice(0, colorIndex), ...old.slice(colorIndex + 1, old.length)]);
  };

  return (
    <SavedColorsContext.Provider value={{ savedColors, saveColor, unsaveColor }}>
      {children}
    </SavedColorsContext.Provider>
  );
};
